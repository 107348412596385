import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtener() {
    var url = ConfigAPI.baseURL + "reports-general" + Session.getToken();
    return instance.get(url);
  },
  mostrar(id) {
    var url = ConfigAPI.baseURL + "reports-general/" + id + Session.getToken();
    return instance.get(url);
  },
  agregar(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "reports-general" + Session.getToken();
    return instance.post(url,params);
  },
  editar(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "reports-general/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminar(id) {
    var url = ConfigAPI.baseURL + "reports-general/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  filtrarReport(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "reports-general/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },  

  obtenerStaff() {
    var url = ConfigAPI.baseURL + "staff" + Session.getToken();
    return instance.get(url);
  },  
  obtenerVendedores() {
    var url = ConfigAPI.baseURL + "sellers" + Session.getToken();
    return instance.get(url);
  },
}

export default servicesAPI;
